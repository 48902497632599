import { Button, Image, Td, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { Link as Link2 } from "react-router-dom";
import useActions from "../../hooks/useActions";
import { deleteMovie, updateMovie } from "../../utils/api";
import useStore from "../../utils/store";
import Buttons from "../common/Actions/Buttons";
import CreateModal from "../common/Modal/Modal";
import CreateTable from "../common/Table/Table";
import Loading from "../common/ui/Loading";
import ModalSub from "../subscription/Modal";
import TableSub from "../subscription/Table";

const Table = ({
  allPodcasts,
  series,
  actions,
  reload,
  updatedSeriesPodcast,
  deleteSeriesPodcast,
  episeries,
  categories,
  seriesEpisodes,
}) => {
  const user = useStore((state) => state.user);
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = React.useState([]);
  const [typeId, setTypeId] = React.useState("");

  const actionsI =
    actions && (user?.updateMovie || user?.deleteMovie) ? "Actions" : false;
  const [updateActionHandler, deleteAction] = useActions();
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <CreateTable
        searchby={!episeries && ["name", "language", "ageLimit", "rating"]}
        pagging={episeries ? false : true}
        head={[
          actionsI,
          "Name",

          "Description",
          !categories && "Subscription",
          "Url",

          "Trailer",
          "Horizontal-Banner",
          "Vertical-Banner",
          (!series || seriesEpisodes) && "Duration",

          !episeries && "Rating",
          !episeries && "Language",
          !episeries && "Age-Limit",
          !episeries && "Cast",
          !episeries && "Extra Cast",
          !episeries && "Genre",
          "Rented",
        ]}
        data={allPodcasts}
        rows={(item) => {
          return (
            <>
              {actions && (user?.updateMovie || user?.deleteMovie) && (
                <Td>
                  {!series && (
                    <ModalSub
                      setLoading={(set) => {
                        setLoading(set);
                      }}
                      episeries={episeries}
                      item={item}
                      reload={reload}
                    />
                  )}

                  {!episeries && (
                    <Button
                      colorScheme={item.isEnable ? "red" : "green"}
                      variant="solid"
                      onClick={async () => {
                        if (!series) {
                          await updateActionHandler(
                            {
                              isEnable: !item.isEnable,
                            },
                            item._id,
                            updateMovie
                          );

                          reload();
                        }
                      }}
                      sx={{
                        marginTop: "10px",
                        width: "100%",
                      }}
                    >
                      {item.isEnable ? "Disable" : "Enable"}
                    </Button>
                  )}

                  <Buttons
                    episeries={episeries}
                    item={item}
                    update={async (data, id) => {
                      if (!series) {
                        setLoading(true);
                        await updateActionHandler(data, id, updateMovie);
                        reload();
                        setLoading(false);
                      } else {
                        updatedSeriesPodcast(data, id);
                      }
                    }}
                    deletePodcast={async () => {
                      if (!series) {
                        setLoading(true);
                        await deleteAction(item._id, deleteMovie);
                        reload();
                        setLoading(false);
                      } else {
                        deleteSeriesPodcast(item._id);
                      }
                    }}
                  />
                </Td>
              )}
              <Td>{item?.name}</Td>
              <Td>{item?.description.slice(0, 25)}...</Td>{" "}
              {!categories && (
                <Td>
                  <Button
                    colorScheme={"blue"}
                    onClick={() => {
                      onOpen();
                      setData(item.subscriptions);
                      setTypeId(item._id);
                    }}
                  >
                    View
                  </Button>
                </Td>
              )}
              <Td>
                {item?.url ? (
                  <Link2 to={"/player"} state={{ url: item?.url }}>
                    <Button colorScheme={"blue"} variant={"link"}>
                      View
                    </Button>
                  </Link2>
                ) : (
                  "No Url Provided"
                )}
              </Td>
              <Td>
                <Link2 to={"/player"} state={{ url: item?.trailer }}>
                  <Button colorScheme={"blue"} variant={"link"}>
                    View
                  </Button>
                </Link2>
              </Td>
              <Td>
                <Image
                  src={item?.horizontalBanner}
                  alt="horizontal-banner"
                  sx={{
                    width: "100px",
                    height: "100px",
                  }}
                  rounded="md"
                />
              </Td>
              <Td>
                <Image
                  src={item?.verticalBanner}
                  alt="vertical-banner"
                  sx={{
                    width: "100px",
                    height: "100px",
                  }}
                  rounded="md"
                />
              </Td>
              {(!series || seriesEpisodes) && (
                <>
                  <Td>{item?.duration}</Td>
                </>
              )}
              {!episeries && (
                <>
                  <Td>{item?.rating}</Td>
                  <Td>{item?.language}</Td>
                  <Td>{item?.ageLimit}</Td>

                  <Td>
                    {item?.cast?.map((item, index) => {
                      return <p key={index}>{item.name}</p>;
                    })}
                  </Td>
                  <Td>{item?.extraCast}</Td>
                  <Td>
                    {item?.genres?.map((item, index) => {
                      return <p key={index}>{item.name}</p>;
                    })}
                  </Td>
                  <Td>{item?.isPaid ? "Yes" : "No"}</Td>
                </>
              )}
            </>
          );
        }}
      />
      <CreateModal
        isOpen={isOpen}
        onClose={() => {
          setData([]);
          setTypeId("");
          onClose();
        }}
        title={"View Subscribtions"}
        table
      >
        <TableSub
          subscribtions={data}
          type="Movie"
          typeId={typeId}
          reload={reload}
          onClose={onClose}
        />
      </CreateModal>
    </>
  );
};

export default Table;
